@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import "./variables.scss";
@import "bootstrap/scss/bootstrap.scss";
@import "./buttons.scss";

html {
	height: 100%;
	width: 100%;
}

body {
	background-repeat: no-repeat;
	overflow-x: hidden;
	font-family: "Poppins", sans-serif;
	min-height: 100%;
	width: 100%;
}

.pointer {
	cursor: pointer;
}

.no-pointer {
	cursor: initial !important;
}

.text-eco {
	color: #666666;
}

.no-text-decoration {
	text-decoration: none !important;
}

.zoom {
	transition: transform 0.2s ease-in-out;
}

.zoom:hover {
	transform: scale(1.2);
}

.btn-outline-primary:hover {
	color: white !important;
}

.no-outline {
	outline: none;
}

.no-select {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                        supported by Chrome, Opera and Firefox */
}

.arrow-primary::marker {
	color: #d7ad2b !important;
}

.arrow-primary::-webkit-details-marker {
	color: #d7ad2b !important;
}

a {
	color: inherit;
}

a:hover {
	color: inherit;
}

.break-all {
	word-break: break-all;
}

@media (max-width: 575.98px) {
	details {
		margin-bottom: 0.8rem !important;
	}
}

.btn-circle {
	border-radius: 100%;
}

.data-table {
	font-size: 0.92rem;

	.table td,
	.table th {
		vertical-align: middle;
	}

	.table th {
		max-width: 300px;
		min-width: 100px;
	}

	.table td:first-child {
		max-width: 320px;
	}

	.table th.md-th {
		min-width: 160px;
	}

	.table th.long-th {
		min-width: 180px;
	}

	.table th.x-long-th {
		min-width: 210px;
	}

	.table td.x-long-td {
		white-space: nowrap;
	}
}

.title-case::first-letter {
	text-transform: uppercase;
}

.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.brand-logo {
	max-width: 220px;
	max-height: 130px;
}

.brand-logo-container {
	height: 130px;
}

.field-tr td,
th {
	white-space: nowrap;
}

.text-line-through {
	text-decoration: line-through;
}

.rounded-right-0 {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.rounded-left-0 {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.text-small {
	font-size: 13px;
}

.text-underline {
	text-decoration: underline;
}

#codeFilter + div {
	z-index: 0;
}

.hideNumberControls::-webkit-inner-spin-button,
.hideNumberControls::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.list-style-none {
	list-style: none;
}
